<template>
  <div>
    <div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 60px">
          <div>
            <div class="resume-title">
              登录账号
            </div>
            <div v-if="data.account" style="display: flex;">
              <div style="width: 220px;margin-right: 16px; font-size: 18px; line-height: 30px">{{ data.account }}</div>
            </div>
            <div v-else>
                <el-button class="wx-btn" @click="function () {type = 1; showPhoneWin = true}">
                  <span class="wx-cont">绑定账户信息</span>
                </el-button>
            </div>
          </div>
          <div>
            <div class="resume-title">
              账号密码
            </div>
            <div style="display: flex;">
              <div style="width: 220px;margin-right: 10px; font-size: 18px; line-height: 30px">********</div>
              <img src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="function () {type = 6; showPhoneWin = true}"/>
            </div>
          </div>
          <div>
            <div class="resume-title">
              手机号码
            </div>
            <div style="display: flex;">
              <div style="width: 220px;margin-right: 16px; font-size: 18px; line-height: 30px">{{ data.phone }}</div>
              <img src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="function () {type = 2; showPhoneWin = true}"/>
            </div>
          </div>
        </div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 30px">
        <div>
          <div class="resume-title">
            绑定邮箱
          </div>
          <div style="display: flex;">
            <div style="width: 220px;margin-right: 16px; font-size: 18px; line-height: 30px">{{ data.email }}</div>
            <img src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="function () {type = 5; showPhoneWin = true}"/>
          </div>
        </div>
        <div>
          <div class="resume-title">
            绑定微信
          </div>
          <div style="display: flex;">
            <div style="width: 220px;margin-right: 10px; font-size: 18px; line-height: 30px">{{data.wxId}}</div>
            <img src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="function () {type = 3; showPhoneWin = true}"/>
          </div>
        </div>
        <div>
          <div class="resume-title">
            绑定QQ
          </div>
          <div style="display: flex;">
            <div style="width: 220px;margin-right: 16px; font-size: 18px; line-height: 30px">{{ data.qqId }}</div>
            <img src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="function () {type = 4; showPhoneWin = true}"/>
          </div>
        </div>
      </div>
<!--      <el-button class="wx-btn" v-if="organForm.account==''" @click="function () {type = 1; showPhoneWin = true}">-->
<!--        &lt;!&ndash;                                            <img class="wx-icon" src="../../assets/imgs/circle/wx.png"/>&ndash;&gt;-->
<!--        <span class="wx-cont">绑定账户信息</span>-->
<!--      </el-button>-->
<!--      <el-button class="wx-btn" v-else @click="function () {type = 6; showPhoneWin = true}">-->
<!--        &lt;!&ndash;                                            <img class="wx-icon" src="../../assets/imgs/circle/wx.png"/>&ndash;&gt;-->
<!--        <span class="wx-cont">修改账户密码</span>-->
<!--      </el-button>-->
<!--      <el-button class="wx-btn" @click="function () {type = 2; showPhoneWin = true}">-->
<!--        &lt;!&ndash;                                            <img class="wx-icon" src="../../assets/imgs/circle/wx.png"/>&ndash;&gt;-->
<!--        <span  v-if="organForm.phone==''" class="wx-cont">绑定手机号</span>-->
<!--        <span  v-else class="wx-cont">更换手机号</span>-->
<!--      </el-button>-->
<!--      <el-button class="wx-btn" @click="function () {type = 3; showPhoneWin = true}">-->
<!--        <img class="wx-icon" src="../../../assets/imgs/circle/wx.png"/>-->
<!--        <span  v-if="organForm.wxId==''" class="wx-cont">绑定微信</span>-->
<!--        <span  v-else class="wx-cont">更换绑定微信</span>-->
<!--      </el-button>-->
<!--      <el-button class="wx-btn" @click="function () {type = 4; showPhoneWin = true}">-->
<!--        <img class="wx-icon" src="../../../assets/imgs/circle/QQ.png"/>-->
<!--        <span v-if="organForm.qqId=='' || organForm.qqId==null"  class="wx-cont">绑定QQ</span>-->
<!--        <span v-else  class="wx-cont">更换绑定QQ</span>-->
<!--      </el-button>-->
<!--      <el-button class="wx-btn" @click="function () {type = 5; showPhoneWin = true}">-->
<!--        &lt;!&ndash;                                            <img class="wx-icon" src="../../assets/imgs/circle/wx.png"/>&ndash;&gt;-->
<!--        <span  v-if="!organForm.email" class="wx-cont">绑定邮箱</span>-->
<!--        <span  v-else class="wx-cont">更换邮箱</span>-->
<!--      </el-button>-->

      <!--绑定手机号弹窗/ 设置账户信息-->
      <LoginModule v-if="showPhoneWin === true" :type="type" :onClose="upDateClose"></LoginModule>
    </div>
  </div>
</template>
<script>

import LoginModule from "@/components/common/LoginModule.vue";
import AddressSelect from "@/components/common/city/AddressSelect.vue";

export default {
  name: 'AccountSetting',
  components: {AddressSelect, LoginModule},
  data() {
    return {
      data: {},

      // 绑定手机号
      type: '',
      showPhoneWin: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getRealInfo()
  },

  methods: {
    getRealInfo() {
      this.newApi.getUserReadNameAuth().then(res => {
        this.data = res.data
      })
    },

    upDateClose() {
      this.showPhoneWin = false;
      this.getRealInfo()
    }
  }
}
</script>

<style scoped>
.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.wx-btn {
  width: 140px;
  height: 40px;
  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.wx-icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
}

.wx-cont {
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
}

/deep/ .el-upload-dragger {
  width: 500px;
  height: 300px;
  background-color: #FBFBFB;
}
</style>
