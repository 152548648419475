<template>
    <div class="section-info">
        <div style="padding: 30px 40px 20px 40px; font-size: 16px;font-weight: 600">
            <div style="margin-bottom: 10px">
                <span>部门名称：{{info.groupName}}</span>
            </div>
            <div>
                部门基本信息：{{info.content}}
            </div>
        </div>
        <el-divider></el-divider>
        <div>
            <table style="width: 100%">
                <tr>
                    <th class="tab-th">子账号昵称</th>
                    <th class="tab-th">子账号类型</th>
                    <th class="tab-th">子账号手机号</th>
                    <th class="tab-th">子账号备注</th>
                    <th class="tab-th">操作</th>
                </tr>
                <tr v-for="(item, index) in subInfo" :key="index">
                    <td class="tab-tb">{{item.nickName}}</td>
                    <td class="tab-tb" v-if="item.isAdmin === 1">管理员</td>
                    <td class="tab-tb" v-if="item.isAdmin === 0">普通成员</td>
                    <td class="tab-tb">{{item.phone}}</td>
                    <td class="tab-tb">{{item.remarks}}</td>
                    <td class="tab-tb"><span @click="outSubs(item)" class="hand op" style="color: #999999; font-size: 14px">移出部门</span></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: "SectionInfo",
    components: {},
    props: {
        info: {
            type: Object,
            defaults() {
	            return {}
            }
        },
    },
	watch:{
		info(data){
			if (data.id!=this.subInfo.id){
				this.getSectionSubs();
			}
		}
	},
    data() {
        return {
            subInfo: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getSectionSubs()
	    this.$EventBus.$on("changeSubData", () => {
		    this.getSectionSubs();
	    })
    },
    methods: {
        // 获取部门成员
        getSectionSubs() {
            this.newApi.getDepartmentUserList({groupId: this.info.id}).then(res => {
                this.subInfo = res.data
            })
        },

        // 移除成员
        outSubs(item) {
            let that = this;
            this.utils.confirm('是否将该子账号移出部门？', function () {
                that.newApi.delUserFromDepartment({groupId: that.info.id, uuid: item.uuid}).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                    }
                })
            })
        },
    }
}
</script>

<style scoped>
    .section-info {
        width: 100%;
    }

    .section-info .tab-th {
        background-color: #F6F6F6;
        font-size: 16px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-weight: 600;
    }

    .section-info .tab-tb {
        font-size: 16px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-bottom: 1px solid #CECFD0;
    }
</style>
