<template>
    <div class="experts-info">
        <div class="content" v-if="tags==='synopsis' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">项目名称：</span>
                    <span>{{sketchInfo.name}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
                    <div style="margin-right: 30px">
                        <img src="../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="sketchInfo.isOvert == 1">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="sketchInfo.isOvert == 0">仅自己可见</span>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="updateShow = true">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">工作内容：</span>
                <span>{{sketchInfo.content}}</span>
            </div>
            <div class="info">
                <span class="content-title">项目周期：</span>
                <span>{{sketchInfo.time}}</span>
            </div>
        </div>

        <ExpertsForm v-if="tags==='synopsis'&& updateShow === true" @cancelAddSketch="updateShow = false" @addSketch="updateSchoolInfo($event, 'synopsis')" tags='synopsis' :expertsEdit="sketchInfo"></ExpertsForm>

        <div class="content" v-if="tags==='monograph' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">专著名称：</span>
                    <span>{{monographInfo.monographName}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
                    <div style="margin-right: 30px">
                        <img src="../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="monographInfo.isOvert == 1">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="monographInfo.isOvert == 0">仅自己可见</span>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="updateShow = true">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">出版社信息：</span>
                <span>{{monographInfo.press}}</span>
            </div>
            <div class="info">
                <span class="content-title">专著其他说明：</span>
                <span>{{monographInfo.explain}}</span>
            </div>
            <div class="info">
                <span class="content-title">出版日期：</span>
                <span>{{monographInfo.time}}</span>
            </div>
        </div>

        <ExpertsForm v-if="tags==='monograph'&& updateShow === true" @cancelAddMonograph="updateShow = false" @addMonograph="updateSchoolInfo($event, 'monograph')" tags='monograph' :expertsEdit="monographInfo"></ExpertsForm>

        <div class="content" v-if="tags==='thesis' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">论文标题：</span>
                    <span>{{paperInfo.paperName}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
                    <div style="margin-right: 30px">
                        <img src="../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="paperInfo.isOvert == 1">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="paperInfo.isOvert == 0">仅自己可见</span>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="updateShow = true">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">发表刊物及刊号：</span>
                <span>{{paperInfo.paperNo}}</span>
            </div>
            <div class="info">
                <span class="content-title">发表日期：</span>
                <span>{{paperInfo.time}}</span>
            </div>
        </div>

        <ExpertsForm v-if="tags==='thesis'&& updateShow === true" @cancelAddPaper="updateShow = false" @addPaper="updateSchoolInfo($event, 'thesis')" tags='thesis' :expertsEdit="paperInfo"></ExpertsForm>

        <div class="content" v-if="tags==='socialTitle' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">社会头衔：</span>
                    <span>{{rankInfo.name}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
                    <div style="margin-right: 30px">
                        <img src="../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>
                        <span style="vertical-align: middle; font-size: 14px" v-if="rankInfo.isOvert == 1">所有人可见</span>
                        <span style="vertical-align: middle; font-size: 14px" v-if="rankInfo.isOvert == 0">仅自己可见</span>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="updateShow = true">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px" class="content-title">头衔证书（不对外展示）</div>
                <img v-if="rankInfo.cart" :src="rankInfo.cart" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
            </div>
        </div>

        <ExpertsForm v-if="tags==='socialTitle'&& updateShow === true" @cancelAddRank="updateShow = false" @addRank="updateSchoolInfo($event, 'socialTitle')" tags='socialTitle' :expertsEdit="rankInfo"></ExpertsForm>

        <div class="content" v-if="tags==='topic' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">专业领域：</span>
                    <span v-if="topicInfo.typeName.length == 3">{{topicInfo.typeName[2]}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
<!--                    <div style="margin-right: 30px">-->
<!--                        <img src="../../../assets/imgs/circle/lock.png" style="vertical-align: middle; margin-right: 4px"/>-->
<!--                        <span style="vertical-align: middle; font-size: 14px" v-if="topicInfo.isOvert == 1">所有人可见</span>-->
<!--                        <span style="vertical-align: middle; font-size: 14px" v-if="topicInfo.isOvert == 2">仅好友可见</span>-->
<!--                        <span style="vertical-align: middle; font-size: 14px" v-if="topicInfo.isOvert == 3">仅自己可见</span>-->
<!--                    </div>-->
                    <div class="hand op" style="margin-right: 30px" @click="upTopic(topicInfo.id)">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags, topicInfo.id)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">专业领域话题：</span>
                <span>{{topicInfo.title}}</span>
            </div>
            <div class="info">
                <span class="content-title">价格：</span>
                <span>{{topicInfo.money}}</span>
            </div>
            <div class="info">
                <span class="content-title">话题描述：</span>
                <span>{{topicInfo.detail}}</span>
            </div>
            <div class="info">
                <span class="content-title">是否上架：</span>
                <span v-if="topicInfo.status == 2">上架</span>
                <span v-if="topicInfo.status == 1">下架</span>
            </div>
        </div>

        <ExpertsForm v-if="tags==='topic'&& updateShow === true" @cancelAddTopic="updateShow = false" @addTopic="updateSchoolInfo($event, 'topic', topicId)" tags='topic' edit='true' :expertsEdit="topicInfo"></ExpertsForm>

        <div class="content" v-if="tags==='expertOther' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                <div style="margin-top: 10px">
                    <span class="content-title">参与制定本专业法律法规、标准规范情况：</span>
                    <span>{{otherInfo}}</span>
                </div>
                <div style="display: flex; vertical-align: middle">
                    <div class="hand op" style="margin-right: 30px" @click="updateShow = true">
                        <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div class="hand op" style="margin-right: 30px" @click="deleteInfo(tags)">
                        <img src="../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                </div>
            </div>
        </div>

        <ExpertsForm v-if="tags==='expertOther'&& updateShow === true" @cancelAddOther="updateShow = false" @addOther="updateSchoolInfo($event, 'expertOther')" tags='expertOther' :othersEdit="otherInfo"></ExpertsForm>
    </div>
</template>

<script>
    import ExpertsForm from "./ExpertsForm";
    export default {
        name: "ExpertsInfo",
        components: {ExpertsForm},
        props: {
            sketchData: {
                type: Object,
                default() {}
            },

            monographData: {
                type: Object,
                default() {}
            },

            paperData: {
                type: Object,
                default() {}
            },

            rankData: {
                type: Object,
                default() {}
            },

            topicData: {
                type: Object,
                default() {}
            },

            otherData: {
                type: String,
                default() {}
            },

            tags: {
                type: String,
                default() {
                    return  '';
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        data() {
            return {
                updateShow: false,

                topicId: '',

                sketchInfo: '',

                monographInfo: '',

                paperInfo: '',

                rankInfo: '',

                topicInfo: '',

                otherInfo: ''
            }
        },

        created() {
            this.sketchInfo = this.sketchData;

            this.monographInfo = this.monographData;

            this.paperInfo = this.paperData;

            this.rankInfo = this.rankData;

            this.topicInfo = this.topicData;

            this.otherInfo = this.otherData;
        },

        methods: {
            // 话题
            upTopic(id) {
                this.updateShow = true;
                this.topicId = id
            },


            // 删除数据
            deleteInfo(e, id){
                if (e == 'topic') {
                    this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res => {
                        if (res.data.length > 1) {
                            this.newApi.delExpertTopic({id: id}).then(res => {
                                if (res.isSuccess == 1) {
                                    this.$emit(e + 'Delete')
                                    this.utils.sus(res.data)
                                }
                            });
                        } else {
                            this.utils.err('必须填写至少一条专家咨询话题！')
                        }
                    })
                } else {
                    this.$emit(e + 'Delete')
                }
            },

            // 修改数据
            updateChange(e){
                this.tags = e;
                this.updateShow = true
            },

            // 保存表单信息
            updateSchoolInfo(e, c) {
                if (c === 'synopsis') {
                    this.updateShow = false;
                    this.sketchInfo = e;
                    this.$emit(c + 'Update', e);
                }
                if (c === 'monograph') {
                    this.updateShow = false;
                    this.monographInfo = e;
                    this.$emit(c + 'Update', e);
                }
                if (c === 'thesis') {
                    this.updateShow = false;
                    this.paperInfo = e;
                    this.$emit(c + 'Update', e);
                }
                if (c === 'socialTitle') {
                    this.updateShow = false;
                    this.rankInfo = e;
                    this.$emit(c + 'Update', e);
                }
                if (c === 'topic') {
                    this.updateShow = false;
                    this.topicInfo = e;
                    this.$emit(c + 'Update', e);
                }
                if (c === 'expertOther') {
                    this.updateShow = false;
                    this.otherInfo = e;
                    this.$emit(c + 'Update', e);
                }
            }
        },
    }
</script>

<style scoped>
    .content {
        font-size: 18px;
        width: 100%;
    }

    .info {
        width: 90%;
        margin-bottom: 20px;
    }

    .content-title {
        font-weight: 600;
    }

    /*公共*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
</style>