<template>
  <div class="account-child">
    <div v-if="JSON.stringify(info) === '{}'">
      <div style="display: flex; margin-bottom: 40px">
        <div style="font-size: 18px; font-weight: 600; margin-right: 30px">当前子账号使用情况：</div>
        <div style="font-size: 16px; line-height: 24px; margin-right: 20px; color: #5BC130"><i class="el-icon-circle-check" style="font-weight: 600"></i> 已激活（{{countSubs.activat}}）</div>
        <div style="font-size: 16px; line-height: 24px; margin-right: 20px; color: #666666"><i class="el-icon-circle-close" style="font-weight: 600"></i> 未激活（{{countSubs.freeze}}）</div>
        <div style="font-size: 16px; line-height: 24px; margin-right: 20px; color: #FF9800"><i class="el-icon-warning-outline" style="font-weight: 600"></i> 已冻结（{{countSubs.unactivat}}）</div>
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div v-for="(item, index) in subList" :key="index">
          <div class="btn" v-if="item.info.status === 0" style="color: #FFFFFF; background-color: #5BC130">{{item.nickName}} <i class="el-icon-circle-check"></i></div>
          <div class="btn" v-if="item.info.status === 1" style="background-color: #E8E8E8">{{item.nickName}} <i class="el-icon-circle-close"></i></div>
          <div class="btn" v-if="item.info.status === 2" style="color: #FFFFFF; background-color: #FF9800">{{item.nickName}} <i class="el-icon-warning-outline"></i></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="account-info">
        <div class="account-tittle">子账号信息</div>
        <div class="account-content">
          <span class="content">子账号登录名：{{info.info.account}}</span>
          <span class="content">子账号手机号：{{info.info.phone}}</span>
        </div>
        <div style="margin-bottom: 10px">
          <div style="display: flex;">
            <span style="font-size: 16px; line-height: 30px;">子账号昵称：</span>
            <div style="min-width: 60px;margin-right: 16px; line-height: 30px;">{{ info.nickName }}</div>
          </div>
        </div>
        <div style="margin-bottom: 10px">
          <div style="display: flex;">
            <span style="font-size: 16px; line-height: 30px;">子账号备注：</span>
            <div style="min-width: 100px;margin-right: 16px; line-height: 30px;">{{ info.info.remarks }}</div>
          </div>
        </div>
      </div>

      <div class="account-info">
        <div class="account-tittle">子账号管理人历史记录</div>
        <div class="account-message" v-for="(item, index) in accountInfo.message" :key="index">
          <div class="message-content">
            <span>{{item.content}}</span>
          </div>
          <div class="message-time">
            <span>{{item.time}}</span>
          </div>
        </div>
      </div>

      <div class="account-info" style="margin-bottom: 80px">
        <div class="account-tittle">账号权限</div>
        <div class="account-content">
          <div class="content" v-for="(item, index) in authList" :key="index">
            <span class="account-power">{{item}}</span>
            <i :class="info.info.isAdmin === 1 ? 'el-icon-success' : 'el-icon-error'"></i>
          </div>
        </div>
      </div>

      <div class="footer">
        <div>
          <span v-if="info.info.status === 0" class="hand op delete" @click="deleteAccountShow = true">冻结该子账号</span>
          <span v-if="info.info.status === 1" class="hand op delete">账号已封</span>
          <span v-if="info.info.status === 2" class="hand op delete" style="color: #FFFFFF;" @click="unsealAccountShow = true">解冻该子账号</span>
          <span class="hand op update" style="background-color: #8BC34A" @click="updateShow = true">重置子账号</span>
          <span v-if="info.info.status === 0 && isAdmin === 1" class="hand op update" style="color: #FFFFFF; margin-left: 30px" @click="setAdmin(0)">取消管理员</span>
          <span v-if="info.info.status === 0 && isAdmin === 0" class="hand op update" style="color: #FFFFFF; margin-left: 30px" @click="setAdmin(1)">设为管理员</span>
        </div>
      </div>

      <el-dialog :visible.sync="deleteAccountShow" width="30%" center>
        <div style="margin: 30px 0; color: #FF0019; text-align: center; font-size: 20px; font-weight: 600">确认冻结这个子账号吗？<br/>冻结后该子账号将无法登录</div>
        <span slot="footer" class="dialog-footer">
                <el-button @click="deleteAccountShow = false" style="margin-right: 20px; background-color: #E4E6EB;">取 消</el-button>
                <el-button type="primary" @click="deleteAccount(2)">确 定</el-button>
            </span>
      </el-dialog>

      <el-dialog :visible.sync="unsealAccountShow" width="30%" center>
        <div style="margin: 30px 0; color: #1C7EFF; text-align: center; font-size: 20px; font-weight: 600">确认解冻这个子账号吗？<br/>解冻后此子账号可以正常使用</div>
        <span slot="footer" class="dialog-footer">
                <el-button @click="unsealAccountShow = false" style="margin-right: 20px; background-color: #E4E6EB;">取 消</el-button>
                <el-button type="primary" @click="deleteAccount(0)">确 定</el-button>
            </span>
      </el-dialog>

      <el-dialog :visible.sync="updateShow" width="30%" center>
        <div style="margin: 30px 0; color: #1479FF; text-align: center; font-size: 20px; font-weight: 600">确认重置这个子账号吗？
          <br/>重置后需要重新绑定手机号和密码</div>
        <span slot="footer" class="dialog-footer">
                <el-button @click="updateShow = false" style="margin-right: 20px; background-color: #E4E6EB;">取 消</el-button>
                <el-button type="primary" @click="updateAccount">确 定</el-button>
            </span>
      </el-dialog>

      <div class="showNote">
        <el-dialog :visible.sync="showNote" width="30%" center>
          <div style="padding: 20px">
            <div style="font-size: 18px; font-weight: 600; text-align: center; margin-bottom: 20px">
              <div>
                正在重置该机构子账号
              </div>
              <div>
                需要重新绑定手机号和更改初始密码
              </div>
            </div>

            <el-form :model="resetAccount" :rules="resetRules" ref="resetForm">
              <div style="display: flex; margin-bottom: 20px">
                <div style="font-size: 18px; margin-bottom: 10px; margin-right: 20px;"><span style="color:#FF0000">*</span>子账号初始密码</div>
                <el-form-item prop="pwd">
                  <el-input v-model="resetAccount.pwd" placeholder="密码至少为8位字符" style="width: 300px">
                  </el-input>
                </el-form-item>
              </div>

              <div style="display: flex; margin-bottom: 20px">
                <div style="font-size: 18px; margin-bottom: 10px; margin-right: 20px;">子账号备注</div>
                <el-form-item prop="remarks">
                  <el-input v-model="resetAccount.remarks" placeholder="子账号分配对象姓名，职务，分管事务等" style="width: 300px">
                  </el-input>
                </el-form-item>
              </div>

              <div style="display: flex; margin-bottom: 20px">
                <div style="font-size: 18px; margin-right: 20px; margin-top: 4px"><span style="color:#FF0000">*</span>手机号</div>
                <el-form-item prop="phone">
                  <el-input name="phone" v-model="resetAccount.phone" placeholder="请输入手机号" style="width: 380px">
                    <template slot="prepend">+86</template>
                  </el-input>
                </el-form-item>
              </div>
              <div style="display: flex; margin-bottom: 20px">
                <div style="font-size: 18px; margin-right: 20px; margin-top: 4px"><span style="color:#FF0000">*</span>验证码</div>
                <el-form-item prop="code">
                  <el-input name="code" v-model="resetAccount.code" placeholder="请输入短信验证码" >
                    <template slot="append">
                      <el-button style="width: 100%;height: 30px;border: 0;background: #F5F7FA" @click="getCode" :disabled="disabled">{{ codeString }}</el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
            </el-form>

            <div style="display: flex; justify-content: center;">
              <div>
                <el-button class="cancel-btn" @click="cancelReset">取消</el-button>
                <el-button class="save-btn" @click="saveReset">重置</el-button>
              </div>
            </div>
          </div>

        </el-dialog>
      </div>

      <el-dialog title="编辑子账号信息" :visible.sync="updateAccountShow" width="30%" center>
        <el-divider></el-divider>
        <div style="padding: 0 20px 20px 20px">
          <el-form :model="accountForm" :rules="accountForm" ref="accountForm">
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号类型
              </div>
              <el-form-item prop="type" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.type"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <div></div>
                <div>
                  <el-button class="cancel-btn" @click="cancel">取消</el-button>
                  <el-button class="save-btn">保存</el-button>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号手机号
              </div>
              <el-form-item prop="number" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.number"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <div></div>
                <div>
                  <el-button class="cancel-btn" @click="cancel">取消</el-button>
                  <el-button class="save-btn">保存</el-button>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号名称
              </div>
              <el-form-item prop="name" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <div></div>
                <div>
                  <el-button class="cancel-btn" @click="cancel">取消</el-button>
                  <el-button class="save-btn">保存</el-button>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号密码
              </div>
              <el-form-item prop="pwd" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.pwd"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <div></div>
                <div>
                  <el-button class="cancel-btn" @click="cancel">取消</el-button>
                  <el-button class="save-btn">保存</el-button>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号备注
              </div>
              <el-form-item prop="explain" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.explain"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <div></div>
                <div>
                  <el-button class="cancel-btn" @click="cancel">取消</el-button>
                  <el-button class="save-btn">保存</el-button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
    export default {
        name: "AccountChild",
        props: {
            info: {
                type: Object,
                defaults() {
                    return {}
                }
            },
        },
        data() {
            return {
                subList: [],
                countSubs: {},
                isAdmin: 0,
                updateShow: false,
                showNote: false,
                authList: ['创建所有Room', '创建机构shop', '添加服务和产品', '添加服务和产品', '创建朋友圈话题'],

                // 验证码状态
                disabled: false,
                codeString: '获取验证码',
                time: 60,
                resetAccount: {
                    uuid: this.info.uuid,
                    code: '',
                    phone: '',
                    pwd: '',
                    remarks: ''
                },
                resetRules: {
                    pwd: [
                        { required: true, message: '请输入子账号密码', trigger: 'blur' },
                        { pattern: /^\w{7,15}$/, message: '密码至少为8位字符', trigger: 'blur'},
                    ],
                    phone: [
                        { required: true, message: '手机号码不能为空', trigger: 'blur' },
                        { pattern: /^1[3|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确手机号码', trigger: 'blur'},
                    ],
                    code: [
                        { required: true, message: '验证码不能为空', trigger: 'blur' }
                    ],
                },

                accountInfo: {
                    uuid: '',
                    type: '管理员',
                    number: '12345678901',
                    name: '大牛',
                    pwd: '123456',
                    explain: '这是一个有管理员权限的子账号',
                    power: {
                        room: true,
                        shop: true,
                        serve: true,
                        environment: true,
                        topic: true
                    }
                },

                deleteAccountShow: false,
                updateAccountShow: false,
                unsealAccountShow: false,
                accountForm: {
                    type: '',
                    number: '',
                    name: '',
                    pwd: '',
                    explain: '',
                },

            }
        },

        watch: {
            info: {
                deep: true,
                handler() {
                  if (this.info.info) {
                    this.isAdmin = this.info.info.isAdmin
                  }
                }
            }
        },

        mounted() {
          if (this.info.info) {
            this.isAdmin = this.info.info.isAdmin
          }
          this.getSubList()
        },

        methods: {
            // 获取子账号列表
            getSubList() {
              this.newApi.getSubAccountList().then(res => {
                this.countSubs = res.data.subAccountCount;
                this.subList = [];
                for (const item of res.data.admin) {
                  this.subList.push(item)
                }
                for (const item of res.data.ordinary) {
                  this.subList.push(item)
                }
              })
            },

            // 设置管理员
            setAdmin(e) {
                let that = this;
                this.newApi.setSubAccountAdmin({isAdmin: e, uuid: this.info.uuid}).then(res => {
                    if (res.isSuccess === 1) {
                      that.utils.sus(res.data);
                      that.$EventBus.$emit('getSubAccountList');
                    }
                })
            },

            // 重置子账号
            saveReset() {
                this.$refs.resetForm.validate((valid) => {
                    if (valid) {
                        this.newApi.resetSubAccount(this.resetAccount).then(res => {
                            if (res.isSuccess == 1) {
                                this.showNote = false;
                                this.$EventBus.$emit('getSubAccountList');
                                this.resetAccount = {
                                    code: '',
                                    phone: '',
                                    pwd: '',
                                    remarks: '',
                                    uuid: this.info.uuid,
                                };
                                this.utils.sus(res.data)
                            } else {
                                this.utils.msg(res.data)
                            }

                        })
                    }
                })
            },

            // 取消重置子账号
            cancelReset() {
                this.showNote = false;
                this.resetAccount = {
                    code: '',
                    phone: '',
                    pwd: '',
                    remarks: '',
                    uuid: this.info.uuid,
                }
            },


            // 获取短信验证码
            getCode() {
                var that = this;
                if(!(/^1[3456789]\d{9}$/.test(that.resetAccount.phone))){
                    that.utils.err('您的手机号输入有误！');
                    return;
                }
                that.changeCode();
                that.newApi.sendSms({mobile: that.resetAccount.phone, smsType: 1}).then(res =>{
                    if (res.isSuccess == 1) {
                        that.utils.sus(res.msg);
                    }
                })
            },

            changeCode(){
                var that = this;
                if(that.time == 0) {
                    that.disabled = false;
                    that.codeString ='获取验证码';
                    that.time  = 60;
                    return;
                } else {
                    that.disabled = true;
                    that.codeString = that.time + '秒后重新发送';
                    that.time --;
                }
                setTimeout(function(){
                    that.changeCode();
                }, 1000)

            },

            // 冻结/解冻 子账号
            deleteAccount(status) {
                this.newApi.accountStatus({uuid: this.info.uuid, status: status}).then(res =>{
                    if (res.isSuccess == 1) {
                        if (status === 2) {
                            this.deleteAccountShow = false;
                            this.info.info.status = 2
                        }
                        if (status === 0) {
                            this.unsealAccountShow = false;
                            this.info.info.status = 0
                        }
                        this.$EventBus.$emit('getSubAccountList');
                        this.utils.sus(res.data);
                    } else {
                        this.utils.msg(res.data);
                    }
                })

            },

            //编辑子账号
            updateAccount() {
                this.updateShow = false;
                this.showNote = true
            },

            // 取消
            cancel() {

            },
        },
    }
</script>

<style scoped>
    .account-child {
        width: 100%;
    }

    .account-child .btn {
      width: 136px;
      text-align: center;
      padding: 8px 0;
      font-size: 16px;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .account-tittle {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .account-info {
        margin-bottom: 30px;
    }

    .account-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .content {
        font-size: 16px;
        line-height: 30px;
        margin-right: 50px;
    }

    .account-power {
        font-size: 16px;
        margin-right: 16px;
    }

    .account-message {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
    }

    .message-content {
        font-size: 16px;
    }

    .message-time {
        font-size: 16px;
        color: #999999;
    }

    .look {
        text-decoration: none;
    }

    .look:hover {
        color: #1C7EFF;
        text-decoration: none;
    }

    .footer {
        display: flex;
        justify-content: center;
    }

    .delete {
        padding: 4px 12px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        background-color: #FF0019;
        color: #FFFFFF;
        margin-right: 30px;
    }

    .update {
        padding: 4px 12px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        background-color: #1C7EFF;
    }

    .form-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .save-btn {
        width: 60px;
        height: 32px;
        color: #fff;
        background-color: #1C7EFF;
        padding: 0;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 60px;
        height: 32px;
        margin-right: 18px;
        background-color: #E4E6EB;
        padding: 0;
        border-radius: 5px;
    }
    /*公共*/

    /deep/ .el-checkbox__inner {
        width: 18px;
        height: 18px;
    }

    /deep/ .el-checkbox__inner::after {
        left: 6px;
        height: 9px;
        width: 4px;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    /deep/ .el-dialog__body {
        padding: 0;
    }

    /deep/ .el-dialog__header {
        margin: 0 4% 0 0;
        padding: 12px 0 0 0;
        font-weight: 600;
    }

    /deep/ .el-dialog__headerbtn {
        top: 16px;
        font-size: 18px;
    }
</style>
