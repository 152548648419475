<template>
    <div class="experts">
        <div v-if="tags === 'synopsis'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    工作业绩
                </div>
                <div v-if="sketchShow === false" class="hand op school-add" @click="sketchShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加业绩</span>
                </div>
                <div v-if="sketchShow === true" style="margin-bottom: 40px">
                    <ExpertsForm :tags="tags" @cancelAddSketch="sketchShow = false" @addSketch="addSketchInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in sketchInfo" :key="JSON.stringify(item)">
                    <ExpertsInfo :tags="tags" :sketchData="item" @synopsisDelete="sketchDelete(index)" @synopsisUpdate="synopsisUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <div v-if="tags === 'monograph'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    出版专著
                </div>
                <div v-if="monographShow === false" class="hand op school-add" @click="monographShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加专著</span>
                </div>
                <div v-if="monographShow === true" style="margin-bottom: 40px">
                    <ExpertsForm tags="monograph" @cancelAddMonograph="monographShow = false" @addMonograph="addMonographInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in monographInfo" :key="JSON.stringify(item)">
                    <ExpertsInfo tags="monograph" :monographData="item" @monographDelete="monographDelete(index)" @monographUpdate="monographUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <div v-if="tags === 'thesis'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    出版论文
                </div>
                <div v-if="paperShow === false" class="hand op school-add" @click="paperShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加论文</span>
                </div>
                <div v-if="paperShow === true" style="margin-bottom: 40px">
                    <ExpertsForm :tags="tags" @cancelAddPaper="paperShow = false" @addPaper="addPaperInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in paperInfo" :key="JSON.stringify(item)">
                    <ExpertsInfo :tags="tags" :paperData="item" @thesisDelete="paperDelete(index)" @thesisUpdate="thesisUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <div v-if="tags === 'socialTitle'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    社会头衔
                </div>
                <div v-if="rankShow === false" class="hand op school-add" @click="rankShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加社会头衔</span>
                </div>
                <div v-if="rankShow === true" style="margin-bottom: 40px">
                    <ExpertsForm :tags="tags" @cancelAddRank="rankShow = false" @addRank="addRankInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in rankInfo" :key="JSON.stringify(item)">
                    <ExpertsInfo :tags="tags" :rankData="item" @socialTitleDelete="rankDelete(index)" @socialTitleUpdate="socialTitleUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <div v-if="tags === 'topic'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    咨询话题
                </div>
                <div v-if="topicShow === false" class="hand op school-add" @click="topicShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加话题</span>
                </div>
                <div v-if="topicShow === true" style="margin-bottom: 40px">
                    <ExpertsForm :tags="tags" @cancelAddTopic="topicShow = false" @addTopic="addTopicInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in topicInfo" :key="item.id">
                    <ExpertsInfo :tags="tags" :topicData="item" @topicDelete="topicDelete(index)" @topicUpdate="topicUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>

        <div v-if="tags === 'expertOther'">
            <div style="margin-bottom: 50px">
                <div class="resume-title">
                    参与制定本专业法律法规、标准规范情况
                </div>
                <div v-if="otherShow === false" class="hand op school-add" @click="otherShow = true">
                    <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                    <span>添加其他</span>
                </div>
                <div v-if="otherShow === true" style="margin-bottom: 40px">
                    <ExpertsForm :tags="tags" @cancelAddOther="otherShow = false" @addOther="addOtherInfo"></ExpertsForm>
                </div>
                <div v-for="(item, index) in otherInfo" :key="item">
                    <ExpertsInfo :tags="tags" :otherData="item" @expertOtherDelete="otherDelete(index)" @expertOtherhUpdate="expertOtherUpdate($event, index)"></ExpertsInfo>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ExpertsForm from "./ExpertsForm";
    import ExpertsInfo from "./ExpertsInfo";
    export default {
        name: "Experts",
        components: {ExpertsInfo, ExpertsForm},
        props: {
            tags: {
                type: String,
                default() {
                    return 'synopsis'
                }
            },

            sketchs: {
                type: Array,
            },
            monographs: {
                type: Array,
            },
            papers: {
                type: Array,
            },
            ranks: {
                type: Array,
            },

            others: {
                type: Array,
            }
        },
        data() {
            return {
                sketchInfo: this.sketchs,
                sketchShow: false,
                indexId: '',

                monographInfo: this.monographs,
                monographShow: false,

                paperInfo: this.papers,
                paperShow: false,

                rankInfo: this.ranks,
                rankShow: false,

                topicInfo: [],
                topicShow: false,

                otherInfo: this.others,
                otherShow: false,
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },
        mounted() {
            if (this.tags == 'topic') {
                this.getExpertTopics()
            }
        },

        methods: {

            // 获取专家话题
            getExpertTopics() {
                this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.topicInfo = res.data
                    }
                })
            },

            addSketchInfo(e) {
                if (e) {
                    this.sketchInfo.push(e);
                    this.sketchShow = false;
                    this.$emit('addExpertInfo', this.sketchInfo)
                }
            },
            sketchDelete(e) {

                this.sketchInfo.splice(e, 1);
                this.$emit('addExpertInfo', this.sketchInfo)
            },
            synopsisUpdate(e, index) {
                if (e) {
                    this.sketchInfo[index] = e
                }
            },

            addMonographInfo(e) {
                if (e) {
                    this.monographInfo.push(e);
                    this.monographShow = false;
                    this.$emit('addExpertInfo', this.monographInfo)
                }
            },
            monographDelete(e) {
                this.monographInfo.splice(e, 1);
                this.$emit('addExpertInfo', this.monographInfo)
            },
            monographUpdate(e, index) {
                if (e) {
                    this.monographInfo[index] = e
                }
            },

            addPaperInfo(e) {
                if (e) {
                    this.paperInfo.push(e);
                    this.paperShow = false;
                    this.$emit('addExpertInfo', this.paperInfo)
                }
            },
            paperDelete(e) {
                this.paperInfo.splice(e, 1);
                this.$emit('addExpertInfo', this.paperInfo)
            },
            thesisUpdate(e, index) {
                if (e) {
                    this.paperInfo[index] = e
                }
            },


            addRankInfo(e) {
                if (e) {
                    this.rankInfo.push(e);
                    this.rankShow = false;
                    this.$emit('addExpertInfo', this.rankInfo)
                }
            },
            rankDelete(e) {
                this.rankInfo.splice(e, 1);
                this.$emit('addExpertInfo', this.rankInfo)
            },
            socialTitleUpdate(e, index) {
                if (e) {
                    this.rankInfo[index] = e
                }
            },

            addTopicInfo(e) {
                if (e) {
                    this.topicInfo.push(e);
                    this.topicShow = false;
                    this.getExpertTopics()
                    // this.$emit('addExpertInfo', this.topicInfo)
                } else {
                    this.getExpertTopics();
                }
            },
            topicDelete(e) {
                this.topicInfo.splice(e, 1);
            },
            topicUpdate(e) {
                if (e) {
                    this.topicShow = false;
                    this.getExpertTopics()
                } else {
                    this.getExpertTopics()
                }
            },

            addOtherInfo(e) {
                if (e) {
                    this.otherInfo.push(e);
                    this.otherShow = false;
                    this.$emit('addExpertInfo', this.otherInfo)
                }
            },
            otherDelete(e) {
                this.otherInfo.splice(e, 1);
                this.$emit('addExpertInfo', this.otherInfo)
            },
            expertOtherUpdate(e, index) {
                if (e) {
                    this.otherInfo[index] = e
                }
            },
        },
    }
</script>

<style scoped>
    .resume-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .school-add {
        color: #1C7EFF;
        font-size: 18px;
        width: 16%;
        margin-bottom: 20px;
    }

    /*公共*/

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
</style>