<template>
    <div class="social-form">
        <div style="margin-bottom: 50px">
            <el-form :model="socialForm" :rules="socialForm" ref="socialForm" class="demo-ruleForm">
                <div class="resume-title">
                    {{socialInfo.name}}二维码名片
                </div>
                <div style="display: flex; flex-wrap: wrap;">
                    <div style="width: 460px; height: 318px; border-radius: 5px; margin-right: 20px; margin-bottom: 10px">
                        <el-upload accept="image/*"  class="upload-demo" :show-file-list=false action="" drag multiple :http-request="uploadImg">
                            <label v-if="socialForm.img">
                                <img :src="socialForm.img" class="avatar" style="width: 100%;height: 100%;">
                            </label>
                            <label v-else>
                                <i class="el-icon-plus"></i>
                                <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                            </label>
                        </el-upload>
                    </div>
                </div>
<!--                <el-divider></el-divider>-->
                <div style="display: flex; justify-content: space-between" v-if="upload">
                    <div></div>
                    <div>
                        <el-button class="cancel-btn" @click="cancel">取消</el-button>
                        <el-button class="save-btn" @click="saveEwm">上传</el-button>
                    </div>
                </div>
            </el-form>

        </div>
    </div>
</template>

<script>
    export default {
        name: "SocialForm",

        props: {
            socialInfo: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        data() {
            return {
                upload:false,
                socialForm: {
                    account: '',
                    img: ''
                },
            }
        },

        methods: {
            // 取消
            cancel() {
                this.$emit('cancelSocialForm')
            },
            saveEwm() {
                this.socialInfo.url=this.socialForm.img
                this.upload = false
            },

            // 上传图片
            uploadImg(params) {
                var that = this;
                var file = params.file;
                const isLt5M = file.size / 1024 / 1024 < 8;
                if (isLt5M) {
                    if (['image/png','image/jpeg', 'image/jpg', 'image/pbmp'].indexOf(file.type) !== -1) {
                        that.utils.upload(file,function(url){
	                        if (!url){
		                        return false;
	                        }
                            that.socialForm.img = url;
                            that.upload = true
                        })
                    } else {
                        this.$message.error('只能上传图片类型!')
                    }

                } else {
                    this.$message.error('上传文件大小不能超过 8MB!')
                }
            },
        }
    }
</script>

<style scoped>
    .resume-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .save-btn {
        width: 68px;
        height: 38px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 38px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    /deep/ .el-upload-dragger {
        width: 450px;
        height: 318px;
        background-color: #FBFBFB;
    }
    .el-icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0,0,0,0.16);
        margin: 100px 0 14px 0;
    }
    .upload-demo {
        vertical-align: middle;
    }
</style>
