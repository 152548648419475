<template>
    <div class="experts-form">
        <el-form v-if="tags === 'synopsis'" :model="sketchInfo" :rules="sketchRules" ref="synopsisForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="项目名称"
                          v-model="sketchInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="8"
                          placeholder="工作内容"
                          v-model="sketchInfo.content"
                          minlength="10"
                          maxlength="500"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>

            <div style="display: flex; margin-bottom: 20px">
                <div style="width: 10%; font-size: 18px">
                    项目周期
                </div>
                <div style="display: flex">
                    <div style="margin-right: 10px">
                        <DatePicker @dateSelected="getStartTime"></DatePicker>
                    </div>
                    <span style="margin-top: 6px">至</span>
                    <div style="margin-left: 20px">
                        <DatePicker @dateSelected="getEndTime"></DatePicker>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="sketchInfo.isOvert" @selectVisible="e => {sketchInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'monograph'" :model="monograph" :rules="monographRules" ref="monographForm" class="demo-ruleForm">
            <el-form-item prop="monographName" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="专著名称"
                          v-model="monograph.monographName"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="synopsis" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="出版社信息"
                          v-model="monograph.press"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="explain" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="6"
                          placeholder="工作内容"
                          v-model="monograph.explain"
                          minlength="10"
                          maxlength="500"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>

            <div style="display: flex; margin-bottom: 20px">
                <div style="width: 10%; font-size: 18px">
                    出版日期
                </div>
                <div style="margin-right: 10px">
                    <DatePicker @dateSelected="getMonographTime"></DatePicker>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="monograph.isOvert" @selectVisible="e => {monograph.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'thesis'" :model="paper" :rules="paperRules" ref="thesisForm" class="demo-ruleForm">
            <el-form-item prop="paperName" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="论文标题"
                          v-model="paper.paperName"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="paperNo" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="发表刊物及刊号"
                          v-model="paper.paperNo"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin-bottom: 20px">
                <div style="width: 10%; font-size: 18px">
                    发表日期
                </div>
                <div style="margin-right: 10px">
                    <DatePicker @dateSelected="getThesisTime"></DatePicker>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="paper.isOvert" @selectVisible="e => {paper.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'socialTitle'" :model="rankInfo" :rules="rankRules" ref="socialTitleForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="头衔名称"
                          v-model="rankInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">头衔证书（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :limit='1' :http-request="($event)=>uploadFrontImg($event,tags)" :on-remove="$event => removeImg($event, tags)">
                        <img v-if="rankInfo.cart" width="100%" height="338px" :src="JSON.parse(JSON.stringify(rankInfo.cart))"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="rankInfo.isOvert" @selectVisible="e => {rankInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'topic'" :model="topicInfo" :rules="topicRules" ref="topicForm" class="demo-ruleForm">
            <el-form-item prop="field" style="margin-bottom: 10px">
                <span style="font-size: 16px; margin-right: 20px">专业领域：</span>
                <el-cascader v-model="selsectClass" :options="classOptions" @change="showResult" style="margin-bottom: 16px"></el-cascader>
            </el-form-item>
            <el-form-item prop="topicField" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="专业领域话题"
                          v-model="topicInfo.title"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="topicField" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          placeholder="价格"
                          v-model="topicInfo.money"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="6"
                          placeholder="话题描述"
                          v-model="topicInfo.detail"
                          minlength="10"
                          maxlength="500"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>

            <el-form-item prop="status" style="margin-bottom: 10px">
                <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
                    <div style="width: 100px;">是否上架：</div>
                    <div style="flex: 1">
                        <el-switch v-model="topicInfo.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1"></el-switch>
                    </div>
                </div>
            </el-form-item>


            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
<!--                <Visible :visible="topicInfo.isOvert" @selectVisible="e => {topicInfo.isOvert = e}"></Visible>-->
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'expertOther'" :rules="otherRules" ref="expertOtherForm" class="demo-ruleForm">
            <el-form-item prop="otherInfo" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="10"
                          placeholder="详细描述专家是否有在主要专业领域参与制定本专业法律法规、标准规范情况"
                          v-model="otherInfo"
                          minlength="10"
                          maxlength="500"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <div></div>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

    </div>
</template>

<script>
    import DatePicker from "./DatePicker";
    import Visible from "./Visible";
    export default {
        name: "ExpertsForm",
        components: {Visible, DatePicker},
        props: {
            tags: {
                type: String,
                default() {
                    return  'synopsis';
                }
            },

            edit: {
                type: String,
                default() {
                    return  '';
                }
            },



            expertsEdit: {
                type: Object,
                default() {
                    return  {};
                }
            },

            othersEdit: {
                type: String,
                default() {
                    return  '';
                }
            }
        },

        data() {
            return {
                // 分类选择
                classOptions: [],
                selsectClass: [],

                // 专家简述
                startTime: '',
                endTime: '',
                sketchInfo: {
                    name: '',
                    content: '',
                    time: '',
                    isOvert: '1'
                },
                sketchRules: {

                },

                // 专著
                monograph: {
                    monographName: '',
                    press: '',
                    explain: '',
                    time: '',
                    isOvert: '1'
                },
                monographRules: {

                },

                // 论文
                paper: {
                    paperName: '',
                    paperNo: '',
                    time: '',
                    isOvert: '1'
                },
                paperRules: {
                },

                // 社会头衔
                rankInfo: {
                    name: '',
                    cart: '',
                    isOvert: '1'
                },
                rankRules: {

                },

                // 咨询话题
                topicInfo: {
                    topicId: '',
                    title: '',
                    money: '',
                    detail: '',
                    typeName: [],
                    typeId: [],
                    status: '',
                },
                topicRules: {

                },

                // 其他认证
                otherInfo: '',
                otherRules: {

                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        mounted() {
            this.getClassInfo();
            if (JSON.stringify(this.expertsEdit) !== "{}") {
                if (this.tags === 'synopsis') {
                    this.startTime = '';
                    this.endTime = '';
                    this.sketchInfo = JSON.parse(JSON.stringify(this.expertsEdit));
                }
                if (this.tags === 'monograph') {
                    this.monograph = JSON.parse(JSON.stringify(this.expertsEdit));
                }
                if (this.tags === 'thesis') {
                    this.paper = JSON.parse(JSON.stringify(this.expertsEdit));
                }
                if (this.tags === 'socialTitle') {
                    this.rankInfo = JSON.parse(JSON.stringify(this.expertsEdit));
                }
                if (this.tags === 'topic') {
                    this.topicInfo = JSON.parse(JSON.stringify(this.expertsEdit));
                }
            }
            if (this.tags === 'expertOther') {
                this.otherInfo = JSON.parse(JSON.stringify(this.othersEdit));
            }
        },

        methods: {
            // 新增专家话题
            addExpertTopics() {
                if (this.topicInfo.topicId) {
                    this.newApi.addExpertTopic({topics: JSON.stringify([this.topicInfo])}).then(res =>{
                        if (res.isSuccess == 1) {
                            this.$emit('addTopic', JSON.parse(JSON.stringify(this.topicInfo)));
                        }
                    })
                } else {
                    this.utils.err('请选择专业领域！')
                }
            },

            // 编辑专家话题
            updateExpertTopics() {
                if (this.topicInfo.topicId) {
                    let data = {
                        id: this.topicInfo.id,
                        title: this.topicInfo.title,
                        money: this.topicInfo.money,
                        detail: this.topicInfo.detail,
                        topicId: this.topicInfo.topicId,
                        status: this.topicInfo.status,
                        uuid: this.user.uuid
                    };
                    this.newApi.editExpertTopic(data).then(res =>{
                        if (res.isSuccess == 1) {
                            this.$emit('addTopic', JSON.parse(JSON.stringify(this.topicInfo)));
                        }
                    });
                } else {
                    this.utils.err('请选择专业领域！')
                }
            },

            // 获取专家话题
            getExpertTopics() {
                this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.topicInfo = res.data
                    }
                })
            },


            showResult(e) {
                if (e.length !== 0) {
                    this.topicInfo.topicId = e[2]
                }
            },


            // 获取行业分类信息
            getClassInfo() {
                var that = this;
                that.newApi.getTypeList({}).then(res =>{
                    that.classOptions = res.data
                })
            },

            // 上传图片
            uploadFrontImg(params, tag) {
                var that = this;
                var file = params.file;
                const isLt5M = file.size / 1024 / 1024 < 8;
                if (isLt5M) {
                    if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                        that.utils.upload(file,function(url){
	                        if (!url){
		                        return false;
	                        }
                            if (tag === 'socialTitle') {
                                that.rankInfo.cart = url
                            }
                        })
                    } else {
                        this.$message.error('只能上传图片类型!')
                    }

                } else {
                    this.$message.error('上传文件大小不能超过 8MB!')
                }
            },

            // 移除文件
            removeImg($event, e) {
                if (e === 'socialTitle') {
                    this.rankInfo.cart = '';
                }
            },

            // 取消
            cancelInfo(e) {
                if (e === 'synopsis') {
                    this.startTime = '';
                    this.endTime = '';
                    this.sketchInfo = {
                        name: '',
                        content: '',
                        time: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddSketch')
                }
                if (e === 'monograph') {
                    this.monograph = {
                        monographName: '',
                        press: '',
                        explain: '',
                        time: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddMonograph')
                }
                if (e === 'thesis') {
                    this.paper = {
                        paperName: '',
                        paperNo: '',
                        time: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddPaper')
                }
                if (e === 'socialTitle') {
                    this.rankInfo = {
                        name: '',
                        cart: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddRank')
                }
                if (e === 'topic') {
                    this.topicInfo = {
                        topicId: '',
                        title: '',
                        money: '',
                        detail: '',
                        typeName: [],
                        typeId: [],
                    };
                    this.$emit('cancelAddTopic')
                }

                if (e === 'expertOther') {
                    this.otherInfo = '';
                    this.$emit('cancelAddOther')
                }
            },

            // 保存表单
            saveInfo(e) {
                if (e === 'synopsis') {
                    this.sketchInfo.time = this.startTime + '至' + this.endTime;
                    this.$emit('addSketch', this.sketchInfo)
                }
                if (e === 'monograph') {
                    this.$emit('addMonograph', this.monograph)
                }
                if (e === 'thesis') {
                    this.$emit('addPaper', this.paper)
                }
                if (e === 'socialTitle') {
                    this.$emit('addRank', this.rankInfo)
                }
                if (e === 'topic' && this.edit === '') {
                    this.addExpertTopics();
                }
                if (e === 'topic' && this.edit === 'true') {
                    this.updateExpertTopics();
                }
                if (e === 'expertOther') {
                    this.$emit('addOther', this.otherInfo)
                }
            },

            // 获取起始时间
            getStartTime(e) {
                if (e) {
                    this.startTime = e
                }
            },

            // 获取结束时间
            getEndTime(e) {
                if (e) {
                    this.endTime = e
                }
            },

            // 获取专著时间
            getMonographTime(e) {
                if (e) {
                    this.monograph.time = e
                }
            },

            // 获取论文时间
            getThesisTime(e) {
                if (e) {
                    this.paper.time = e
                }
            }
        }
    }
</script>

<style scoped>
    .open-btn {
        width: 72px;
        height: 36px;
        padding: 2px 0;
        line-height: 6px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .save-btn {
        width: 68px;
        height: 36px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 36px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .resume-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .upload-demo {
        vertical-align: middle;
    }

    .el-icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        margin: 100px 0 14px 0;
    }

    /deep/ .el-upload-dragger {
        width: 516px;
        height: 338px;
        background-color: #FBFBFB;
    }
</style>
