<template>
    <div class="card">
        <div class="card-header">
          <div style="display: flex;">
            <div class="hand op" style="font-size: 20px; font-weight: 600" @click="backHome">
              < 返回
            </div>

            <div style="font-size: 20px; font-weight: 600; margin-left: 450px">我的名片</div>
          </div>
          <CardContent :cardInfo="cardInfo"></CardContent>
          <div style="display: flex; justify-content: space-between; margin: 20px 0 0 10px" v-if="user.uuid === userInfo.uuid">
            <div style="display: flex">
              <div v-for="(item, index) in 5" :key="index" @click="checkType(item)" :class="cardInfo.type === item ? 'select-tag' : 'tag'"  class="hand op">模板{{item}}</div>
            </div>
            <div class="select-tag hand op" @click="upShow = true">编辑名片</div>
          </div>
        </div>
        <div class="data-body" v-if="userInfo.uuid === user.uuid">
            <div style="width: 100%; height: 60px; border-bottom: 1px #CED0D4 solid; display: flex; justify-content: space-between">
              <div style="font-size: 20px; font-weight: 600; padding: 16px 20px">
                我收到的名片
              </div>
              <div style="margin-top: 12px; margin-right: 20px">
                <el-autocomplete
                    style="display:inline;"
                    value-key="nickName"
                    v-model="searchContent"
                    :fetch-suggestions="querySearchAsync"
                    @select="selectOne"
                    @clear="getCard"
                    placeholder="请输入用户昵称搜索"
                    :highlight-first-item="true"
                    :trigger-on-focus="false"
                    :clearable = "true"
                ></el-autocomplete>
              </div>
            </div>
          <div style="padding: 20px 40px">
            <div v-for="(item, index) in cardList" :key="index" style="margin-bottom: 20px">
              <div v-if="item.visitingCard">
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                  <div>
                    <CircleUser :userData="item"></CircleUser>
                  </div>
                  <div class="hand op tag" @click="toSpace(item)" style="height: 24px; font-weight: 600; font-size: 14px; margin-top: 10px">进入个人空间</div>
                </div>
                <div>
                  <CardContent :cardInfo="item.visitingCard"></CardContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      <el-dialog :visible.sync="upShow" width="1000px" :show-close="false" class="event-form">
        <div class="top">
          编辑名片信息
          <img src="@/assets/imgs/circle/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="upShow = false">
        </div>
        <div style="padding: 20px 40px">
          <div>
            <el-form :model="cardInfo" :rules="cardRules" ref="card" class="demo-ruleForm">
              <div style="display: flex">
                <el-form-item prop="name" style="margin-bottom: 20px; flex: 1; margin-right: 30px">
                  <div><span style="color: #FF0000">*</span><span>姓名</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="20"
                            :show-word-limit="true"
                            v-model="cardInfo.name">
                  </el-input>
                </el-form-item>
                <el-form-item prop="duties" style="margin-bottom: 20px; flex: 1">
                  <div><span style="color: #FF0000">*</span><span>职务</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="20"
                            :show-word-limit="true"
                            v-model="cardInfo.duties">
                  </el-input>
                </el-form-item>
              </div>

              <div style="display: flex">
                <el-form-item prop="phone" style="margin-bottom: 20px; flex: 1; margin-right: 30px">
                  <div><span style="color: #FF0000">*</span><span>手机号码</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="11"
                            :show-word-limit="true"
                            v-model="cardInfo.phone">
                  </el-input>
                </el-form-item>
                <el-form-item prop="email" style="margin-bottom: 20px; flex: 1">
                  <div><span style="color: #FF0000">*</span><span>电子邮箱</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="30"
                            :show-word-limit="true"
                            v-model="cardInfo.email">
                  </el-input>
                </el-form-item>
              </div>

              <div style="display: flex">
                <el-form-item prop="company" style="margin-bottom: 20px; flex: 1; margin-right: 30px">
                  <div><span style="color: #FF0000">*</span><span>公司名称</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="50"
                            :show-word-limit="true"
                            v-model="cardInfo.companyName">
                  </el-input>
                </el-form-item>
                <el-form-item prop="address" style="margin-bottom: 20px; flex: 1">
                  <div><span style="color: #FF0000">*</span><span>通讯地址</span></div>
                  <el-input type="text"
                            resize="none"
                            maxlength="50"
                            :show-word-limit="true"
                            v-model="cardInfo.address">
                  </el-input>
                </el-form-item>
              </div>
              <div style="display: flex">
                <div style="margin-right: 60px">
                  <div style="margin-bottom: 10px"><span>公司Logo</span></div>
                  <el-form-item prop="logo">
                    <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(val) => uploadImg(val,'logo')">
                      <img v-if="cardInfo.logo" width="100%" height="338px" :src="cardInfo.logo"/>
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </div>
                <div style="margin-right: 100px">
                  <div style="margin-bottom: 10px"><span>公司二维码</span></div>
                  <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(val) => uploadImg(val,'code')">
                    <img v-if="cardInfo.ewm" width="100%" height="338px" :src="cardInfo.ewm"/>
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
                <div>
                  <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span>公司简介</span></div>
                  <el-form-item prop="content">
                    <el-input type="textarea"
                              :rows="7"
                              maxlength="200"
                              style="width: 440px"
                              :show-word-limit="true"
                              v-model="cardInfo.content">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="display: flex; justify-content: end">
                <el-button class="save-btn" @click="saveCardInfo">保存信息</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import SocialForm from "./components/SocialForm";
    import SocialContent from "./components/SocialContent";
    import AddressSelect from "../../components/common/city/AddressSelect";
    import * as url from "url";
    import CardContent from "@/pages/circle/components/CardContent.vue";
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    export default {
        name: "Card",
        components: {CircleUser, CardContent, AddressSelect, SocialContent, SocialForm},
        data() {
            return {
              upShow: false,
              selectStyle: {},
              searchContent: '',
              backStyles: [
                {id: 1, first:'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3578.png', second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3578.png'},
                {id: 2, first:'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3579.png', second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3579.png'},
                {id: 3, first:'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3580.png', second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3580.png'},
                {id: 4, first:'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3581.png', second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3581.png'},
                {id: 5, first:'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3582.png', second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3582.png'},
              ],

              cardInfo: {
                name: '',
                duties: '',
                phone: '',
                email: '',
                companyName: '',
                address: '',
                logo: '',
                ewm: '',
                content: '',
                type: 1
              },

              cardRules: {
                name: [{required: true, message: '请填写姓名', trigger: 'blur'}],
                duties: [{required: true, message: '请填写职务', trigger: 'blur'}],
                phone: [{required: true, message: '请填写手机号码', trigger: 'blur'},{pattern: /^1[3-9]\d{9}$/, message: '请填写正确的手机号码', trigger: 'blur'}],
                email: [{required: true, message: '请填写电子邮箱', trigger: 'blur'},{pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请填写正确的手机号码', trigger: 'blur'}],
                companyName: [{required: true, message: '请填写公司名称', trigger: 'blur'}],
                address: [{required: true, message: '请填写通讯地址', trigger: 'blur'}],
                content: [{required: true, message: '请填写公司简介', trigger: 'blur'}],
              },

              cardList: []
            }
        },

        props: {
          userInfo: {
            type: Object,
            default() {
              return {}
            }
          }
        },

        computed: {
          url() {
            return url
          },
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        mounted() {
          this.getCard()
        },

        methods: {
          //搜索
          querySearchAsync(searchContent, cb) {
            let list = this.cardList.filter(item => {
              return item.nickName.includes(searchContent)
            })
            cb(list)
          },

          selectOne(item) {
            this.cardList = [item];
          },

          // 更换皮肤
          checkType(type) {
            let that = this
            this.cardInfo.type = type
            let params = {};
            params = that.cardInfo;
            that.newApi.setVisitingCard(params).then(res => {
              if (res.isSuccess === 1) {
                that.utils.sus(res.data)
                that.upShow = false
                that.getCard()
              }
            })
          },

          // 获取名片信息
          getCard() {
            this.newApi.getSpaceInfo().then(res => {
              if (res.data.visitingCard) {
                if (!res.data.visitingCard.type) {
                  res.data.visitingCard.type = 1
                }
                this.cardInfo = res.data.visitingCard
              }
              if (res.data.receiveVisitingCardList) {
                this.cardList = res.data.receiveVisitingCardList
              }

            })
          },

          // 进入个人空间
          toSpace(user) {
            if (user.userType === 1) {
              this.utils.b("/circle/mySpace/" + user.uuid)
            }
            if (user.userType === 2) {
              this.utils.b('/circle/organSpace/' + user.uuid)
            }
            if (user.userType === 3) {
              this.utils.b('/circle/organSpace/' + user.uuid)
            }
          },

          // 返回个人空间首页
          backHome() {
            this.$emit('checkoutHome', 'normal')
          },

          // 保存信息
          saveCardInfo() {
            let that = this;
            that.$refs['card'].validate(function (valid) {
              if (valid) {
                let params = {};
                if (that.cardInfo.type === 0) {
                  that.cardInfo.type = 1
                }
                params = that.cardInfo;
                that.newApi.setVisitingCard(params).then(res => {
                  if (res.isSuccess === 1) {
                    that.utils.sus(res.data)
                    that.upShow = false
                    that.getCard()
                  }
                })
              }
            })
          },

          // 上传图片
          uploadImg(params, name) {
            var that = this;
            var file = params.file;
            const isLt5M = file.size / 1024 / 1024 < 10;
            if (isLt5M) {
              if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                that.utils.upload(file,function(url){
                  if (!url){
                    return false;
                  }
                  if (name === 'logo') {
                    that.cardInfo.logo = url.url
                  }
                  if (name === 'code') {
                    that.cardInfo.ewm = url.url
                  }
                })
              } else {
                this.$message.error('只能上传图片类型!')
              }

            } else {
              this.$message.error('上传文件大小不能超过 10MB!')
            }
          }
        },
    }
</script>

<style scoped>
    .card {
        width: 100%;
    }

    .card-header {
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        padding: 20px;
        margin-bottom: 10px;
    }

    .tag {
      padding: 2px 10px;
      color: #1479FF;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #1479FF;
      margin-right: 10px;
    }

    .card-header .select-tag {
      padding: 2px 10px;
      background-color: #1479FF;
      font-size: 16px;
      border-radius: 5px;
      color: #FFFFFF;
      margin-right: 10px;
    }

    .data-body {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .save-btn {
      width: 98px;
      height: 38px;
      color: #fff;
      background-color: #1C7EFF;
      border-radius: 5px;
    }

    .event-form .top {
      text-align: center;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #444444;
      border: 1px solid #CECFD0;
      height: 60px;
      box-sizing: border-box;
      line-height: 60px;
    }

    /*下划线*/
    .el-divider--vertical {
        margin: 0;
        width: 2px;
        height: 100%;
    }

    .el-menu {
      border-right: none;
    }

    .el-icon-plus {
      color: #2970FF;
      font-size: 44px;
      font-weight: 600;
      text-shadow: 0 3px 6px rgba(0,0,0,0.16);
    }

    /deep/ .el-upload-dragger {
      width: 160px;
      height: 160px;
      line-height: 160px;
      background-color: #FBFBFB;
    }

    /deep/.event-form .el-dialog__header {
      padding: 0;
    }

    /deep/.event-form .el-dialog__body {
      padding: 0;
    }

</style>
