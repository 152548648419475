<template>
    <div class="message-box">
        <div class="message-body">
            <div style="display: flex; justify-content: end">
                <img  @click="close" src="../../../assets/imgs/circle/cancel.png" class="hand op" style="width: 26px; height: 26px; margin: 10px">
            </div>
            <div style="width: 100%; text-align: center">
                <div style="padding: 0 40px 30px 40px">
                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 10%">
                        {{title}}
                    </div>
                    <div @click="toMoney" class="hand op" style="font-size: 16px; font-weight: 600; color: #1479FF; margin-bottom: 10px">
                        跳转至会员中心
                    </div>
                    <div style="font-size: 14px; color: #999999">
                        {{content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MessageVip",
    components: {},
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: '提示'
        },

        content: {
            type: String,
            default: '（充值百工宝年费以创建子账号）'
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 跳转至钱包
        toMoney() {
            this.utils.b('/member')
        },

        // 关闭弹窗
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
    .message-box {
        position:fixed;
        z-index:1213;
        top:40%;left:40%;
    }

    .message-body {
        background:#fff;
        border-radius:5px;
        box-shadow: 0 1px 10px 1px rgba(0,0,0,0.16);
        width: 436px;
        height: 211px;
        position:absolute;
        margin:20% auto;
    }
</style>
