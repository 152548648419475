<template>
  <div class="card-info">
      <div style="display: flex; justify-content: space-between; margin-top: 20px">
        <div style="width: 548px; height: 318px; position: relative" :style="{backgroundImage: 'url(' + backStyles.filter(e => {return e.id === cardInfo.type})[0].first + ')'}">
          <div style="position: absolute; top: 40px; left: 60px; width: 132px; text-align: center">
            <img v-if="cardInfo.logo" :src="cardInfo.logo" style="width :60px; height: 60px">
            <img v-else style="width :60px; height: 60px; background-color: #c1c1c1">
            <div style="font-size: 20px; font-weight: 600; margin: 10px 0"><span v-if="cardInfo.companyName">{{cardInfo.companyName}}</span><span v-else>公司名称</span></div>
            <img v-if="cardInfo.ewm" :src="cardInfo.ewm" style="width :60px; height: 60px">
            <img v-else style="width :60px; height: 60px; background-color: #c1c1c1">
          </div>
          <div style="position: absolute; top: 40px; left: 256px; color: #FFFFFF;">
            <div style="font-size: 22px; margin-bottom: 8px"><span v-if="cardInfo.name">{{cardInfo.name}}</span><span v-else>姓名</span></div>
            <div style="font-size: 16px"><span v-if="cardInfo.duties">{{cardInfo.duties}}</span><span v-else>职务</span></div>
          </div>
          <div style="position: absolute; top: 130px; left: 300px; color: #FFFFFF; font-size: 18px">
            <div style="margin-bottom: 28px"><span v-if="cardInfo.phone">{{cardInfo.phone}}</span><span v-else>手机号码</span></div>
            <div style="margin-bottom: 24px"><span v-if="cardInfo.email">{{cardInfo.email}}</span><span v-else>邮箱号码</span></div>
            <div><span v-if="cardInfo.address">{{cardInfo.address}}</span><span v-else>公司地址</span></div>
          </div>
        </div>
        <div style="width: 548px; height: 318px;" :style="{backgroundImage: 'url(' + backStyles.filter(e => {return e.id === cardInfo.type})[0].second + ')'}">
          <div style="text-align: center; margin-top: 80px">
            <img v-if="cardInfo.logo" :src="cardInfo.logo" style="width :60px; height: 60px">
            <img v-else style="width :60px; height: 60px; background-color: #c1c1c1">
            <div style="font-size: 16px; padding: 0 30px; font-weight: 600; margin: 10px 0; word-wrap: break-word;"><span v-if="cardInfo.content">{{cardInfo.content}}</span><span v-else>公司简介</span></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "CardContent",
  components: {},
  data() {
    return {
      upShow: false,
      backStyles: [
        {
          id: 1,
          first: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3578.png',
          second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3578.png'
        },
        {
          id: 2,
          first: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3579.png',
          second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3579.png'
        },
        {
          id: 3,
          first: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3580.png',
          second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3580.png'
        },
        {
          id: 4,
          first: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3581.png',
          second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3581.png'
        },
        {
          id: 5,
          first: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/f3582.png',
          second: 'https://baigongbao.oss-cn-beijing.aliyuncs.com/img/circle/b3582.png'
        },
      ],
    }
  },

  props: {
    cardInfo: {
      type: Object,
      default() {
        return {
          type: 1
        }
      }
    },
    selectStyle: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  computed: {
    url() {
      return url
    },
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  created() {
  },

  mounted() {


  },

  methods: {
  },
}
</script>

<style scoped>
.card-content {
  width: 100%;
}

</style>
