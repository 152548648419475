<template>
  <div class="message">
    <div class="data-message" style="padding: 30px">
          <div style="min-height: 180px">
            <div class="resume-title">
              <span style="margin-right: 20px">主人寄语</span>
              <span v-if="uuid == user.uuid && isEdit == false"  @click="isEdit = true" class="hand" style="font-size: 16px; color: #1479FF; font-weight: 400">编辑您的寄语</span>
            </div>

            <div v-if="isEdit == false">
              <span v-if="spaceInfo.messageAndReply.myMessage">{{spaceInfo.messageAndReply.myMessage}}</span>
              <span v-else>主人很懒，什么都没有说。</span>
            </div>
            <div v-else>
              <el-input type="textarea"
                        resize="none"
                        :rows="4"
                        placeholder="写点什么吧。"
                        v-model="spaceInfo.messageAndReply.myMessage"
                        maxlength="200"
                        :show-word-limit="true"
                        style="margin-bottom: 16px">
              </el-input>
              <div style="display: flex; justify-content: end">
                <el-button class="cancel-btn" @click="cancelWord">取消</el-button>
                <el-button class="save-btn" @click="sendWord">发表</el-button>
              </div>
            </div>
          </div>

          <div style="min-height: 180px" v-if="uuid !== user.uuid">
            <div class="resume-title">
              <span style="margin-right: 20px">发表您的留言</span>
            </div>
            <div>
              <el-input type="textarea"
                        resize="none"
                        :rows="5"
                        placeholder="说点什么吧。"
                        v-model="messageContent"
                        maxlength="300"
                        :show-word-limit="true"
                        style="margin-bottom: 16px">
              </el-input>
              <div style="display: flex; justify-content: end">
                <el-button class="cancel-btn" @click="messageContent = ''">取消</el-button>
                <el-button class="save-btn" @click="upMessage(uuid)">发表</el-button>
              </div>
            </div>
          </div>
          <div>
            <div class="resume-title">留言（{{messageList.length}}）</div>
            <el-divider></el-divider>
            <div v-for="(item, index) in messageList" :key="index">
              <div class="user">
                <img :src="item.commenterUserInfo.headIco + '-200x200'" class="userIco" >
                <div class="major">
                  <div class="userName">
                    <div>{{item.commenterUserInfo.nickName}}</div>
                    <img v-show="item.commenterUserInfo.authenticationStatus >= 2" src="@/assets/imgs/circle/p.png" style="width: 24px;height: 24px;margin-left: 6px;"/>
                    <img v-show="item.commenterUserInfo.authenticationStatus >= 3" src="@/assets/imgs/circle/s.png" style="width: 24px;height: 24px;margin-left: 6px;"/>
                    <img v-show="item.commenterUserInfo.v == 1" src="@/assets/imgs/circle/v.png" style="width: 20px;height: 22px;margin-left: 6px;"/>
                    <div class="userMajor">第{{index + 1}}楼</div>
                  </div>
                  <div class="message-content">
                    {{item.content}}
                  </div>
                  <div style="font-size: 18px; margin: 20px" v-for="(reply, index) in item.replyList" :key="index">
                    <span style="color: #1479FF">{{reply.user.nickName}}：</span>
                    <span style="margin-right: 50px">{{reply.content}}</span>
                    <span class="hand" style="color: #1479FF; font-size: 16px" @click="deleteReply(reply.id)" v-if="uuid == user.uuid">删除</span>
                  </div>
                  <div class="message-time">
                    <span style="margin-right: 30px">{{item.time}}</span>
                    <span class="hand" style="color: #1479FF; margin-right: 30px" @click="item.isReply = !item.isReply" v-if="uuid == user.uuid">回复</span>
                    <span class="hand" style="color: #1479FF" @click="deleteMessage(item.id)" v-if="uuid == user.uuid">删除</span>
                  </div>
                  <div v-if="item.isReply === true" style="display: flex; margin-top: 10px">
                    <el-input style="width: 1080px; height: 32px; margin-right: 12px" v-model="replayMessage"></el-input>
                    <img @click="sendReplayMessage(item.id, index)" src="@/assets/imgs/circle/send.png" class="hand op sc" style="width: 24px; height: 24px; margin-top: 6px"/>
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "MessageBoard",
  components: {
  },
  data() {
    return {
      spaceInfo: {
        messageAndReply: {},
        personalData: {},
        shopSpaceInfo: {},
        spaceSize: {},
        userSpaceInfo: {},
        visitingCard: {}
      },

      // 留言板
      showMessage: false,
      isEdit: false,
      isReply: false,
      replayMessage: '',
      words: '',
      messageContent: '',
      messageList: [],

    }
  },

  props: {
    uuid: {
      Object: String,
      default() {
        return ''
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  watch: {
  },

  created() {
  },

  mounted() {
    this.getSpaceInfo(this.uuid)
  },

  methods: {
    // 获取空间信息
    getSpaceInfo(uuid) {
      this.newApi.getSpaceInfo({uuid: uuid}).then(res => {
        for (const item of res.data.messageAndReply.MessageAndReplyList) {
          item.isReply = false
        }
        this.messageList = res.data.messageAndReply.MessageAndReplyList;
        this.spaceInfo = res.data
      })
    },

    // 删除留言
    deleteMessage(id) {
      this.newApi.delLeaveMessage({messageId: id}).then(res =>{
        if (res.isSuccess == 1) {
          this.getSpaceInfo(this.uuid)
          this.utils.sus(res.data)
        }
      })
    },

    // 删除回复
    deleteReply(id) {
      this.newApi.delLeaveMessageReply({replyId: id}).then(res =>{
        if (res.isSuccess == 1) {
          this.getSpaceInfo(this.uuid)
          this.utils.sus(res.data)
        }
      })
    },

    // 发表主人寄语
    sendWord() {
      this.newApi.saveMyBlessingWords({content: this.spaceInfo.messageAndReply.myMessage}).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data)
          this.$emit('onChange', this.uuid)
        }
      })
      this.isEdit = false
    },

    // 取消主人寄语
    cancelWord() {
      this.words = '';
      this.isEdit = false
    },

    // 获取留言
    getLeaveMessageAndReplyList() {
      this.newApi.getLeaveMessageAndReplyList({type: 2, uuid: this.uuid}).then(res => {
        this.messageList = res.data;
      })
    },

    // 发表留言
    upMessage(uuid) {
      this.newApi.addLeaveMessage({commenterUuid: uuid, content: this.messageContent}).then(res => {
        if (res.isSuccess == 1) {
          this.getSpaceInfo(this.uuid)
          this.messageContent = '';
          this.utils.sus(res.data)
        } else {
          this.utils.err(res.data)
        }
      })
    },

    // 发表回复
    sendReplayMessage(id, index) {
      this.newApi.addLeaveMessageReply({messageId: id, content: this.replayMessage}).then(res => {
        if (res.isSuccess == 1) {
          this.getSpaceInfo(this.uuid)
          this.messageList[index].isReply = false
          this.replayMessage = ''
          this.utils.sus(res.data)
        }
      })
    },

  }
}
</script>

<style scoped>
.data-message {
  width: 96%;
  background-color: #fff;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
  margin-bottom: 10px;
}

.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.save-btn {
  width: 68px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}

.cancel-btn {
  width: 68px;
  height: 38px;
  margin-right: 18px;
  background-color: #E4E6EB;
  border-radius: 5px;
}


.user{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.user .userIco{
  width: 80px;
  height: 80px;
  background-color: #707070;
  border-radius: 50%;
  margin-right: 16px;
}
.user .major .userName{
  font-weight: 600;
  color: #1C7EFF;
  font-size: 22px;
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
  line-height: 24px;
}
.major .userMajor{
  font-size: 18px;
  color: #999999;
  font-weight: 400;
  margin-left: 20px;
}

.major .message-content {
  min-height: 80px;
  font-size: 18px;
}

.major .message-time {
  font-size: 18px;
  color: #999999;
}

.el-divider--vertical {
  margin: 0;
  width: 2px;
  height: 100%;
}

.el-menu {
  border-right: none;
}

/deep/ .el-submenu__icon-arrow {
  display: none !important;
}

/deep/ .el-submenu__title {
  height: 42px;
  line-height: 42px;
}

/deep/ .el-submenu__title:hover {
  background-color: #F5F5F5;
  height: 42px;
}

.el-submenu__title i{
  color: #1a1a1a;
  line-height: 26px;
}

/deep/ .search-input .el-input__inner {
  height: 32px;
  background-color: #F0F2F5;
  border-radius: 50px;
  padding-left: 40px;

}

/deep/ .el-input__prefix {
  color: #1C7EFF;
  font-size: 18px;
  margin-top: 2px;
  font-weight: 600;
}

</style>
