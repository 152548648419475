<template>
    <div class="social-content">
        <div class="resume-title">
            {{socialItem.title}}
        </div>
        <div class="hand op school-add" @click="addAccount()" v-if="socialItem.url=='' && socialItem.account=='' && showEdit === false">
            <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
            <span>添加账号</span>
        </div>

        <div style="display: flex; margin-bottom: 16px" v-if="showEdit==false">
            <div class="account-edit" >
                {{socialItem.title}}账号：{{socialItem.account}}
            </div>
            <div>
                <img src="../../../assets/imgs/circle/editorC.png" class="hand op updateTags" @click="showEdit = true"/>
            </div>
        </div>
            <div style="display: flex; margin-bottom: 16px" v-if="showEdit === true">
                <div class="account-edit" >
                    {{socialItem.title}}账号：
                    <el-input
                        class="input-new-tag"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="small"
                    >
                    </el-input>
                </div>
                <div>
                    <el-button class="save-btn" @click="handleInputConfirm">确定</el-button>
                </div>
            </div>
            <div style="width: 90%" v-if="showEdit === true || img!=''">
                <div style="display: flex; flex-wrap: wrap;">
                    <div style="width: 460px; height: 318px; border-radius: 5px; margin-right: 20px; margin-bottom: 50px">
                        <div class="resume-title">
                            {{socialItem.title}}二维码名片
                        </div>
                        <el-upload  accept="image/*"  class="upload-demo" :show-file-list=false action="" drag multiple :http-request="uploadImg">
                            <label v-if="img">
                                <img :src="img" class="avatar" style="width: 100%;height: 100%;">
                            </label>
                            <label v-else>
                                <i class="el-icon-plus"></i>
                                <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                            </label>
                        </el-upload>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between;" v-if="upload" >
                    <div></div>
                    <div>
                        <el-button class="cancel-btn" @click="cancelSocialForm">取消</el-button>
                        <el-button class="save-btn" @click="saveEwm">上传</el-button>
                    </div>
                </div>
            </div>

    </div>
</template>

<script>
    // import SocialForm from "./SocialForm";
    export default {
        name: "SocialContent",
        // components: {SocialForm},
        props: {
            socialItem: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                accountEdit:true,
                showEdit:false,
                upload:false,
                img: '',
                accountInput: false,
                inputValue: '',
            }
        },
        mounted() {
            this.img=this.socialItem.url;
        },
        methods: {
            saveEwm() {
                this.socialItem.url=this.img;
                this.upload = false;
                this.$parent.contact();
            },

            // 上传图片
            uploadImg(params) {
                var that = this;
                var file = params.file;
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (isLt5M) {
                    if (['image/png','image/jpeg', 'image/jpg', 'image/pbmp'].indexOf(file.type) !== -1) {
                        that.utils.upload(file,function(url){
	                        if (!url){
		                        return false;
	                        }
                            that.img = url.url
                            that.upload = true
                        })
                    } else {
                        this.$message.error('只能上传图片类型!')
                    }

                } else {
                    this.$message.error('上传文件大小不能超过 5MB!')
                }
            },
            addAccount() {
                this.showEdit = true
            },

            // 更新账号
            handleInputConfirm() {
                if (this.inputValue) {
                    this.socialItem.account = this.inputValue;
                    this.$parent.contact();
                }
                this.inputValue = '';
                this.showEdit = false
            },

            // 取消添加账号
            cancelSocialForm() {
                this.upload = false;
                this.img=this.socialItem.url;
            }
        }
    }
</script>

<style scoped>
    .social-content {
        width: 100%;
    }

    .updateTags {
        width: 28px;
        height: 28px;
        margin-left: 30px;
        vertical-align: bottom
    }

    .resume-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .school-add {
        color: #1C7EFF;
        font-size: 18px;
        width: 26%;
        margin-bottom: 20px;
    }

    .account-edit {
        font-size: 16px;
        color: #999999;
    }

    .updateTags {
        width: 28px;
        height: 28px;
        margin-left: 60px;
    }

    .input-new-tag {
        width: 240px;
        vertical-align: middle;
        display: inline-block;
    }

    .save-btn {
        width: 60px;
        height: 32px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
        margin-left: 60px;
    }

    /*公共*/

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    .el-button {
        padding: 10px;
    }

    /deep/ .el-upload-dragger {
        width: 450px;
        height: 318px;
        background-color: #FBFBFB;
    }
    .el-icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0,0,0,0.16);
        margin: 100px 0 14px 0;
    }
    .upload-demo {
        vertical-align: middle;
    }
</style>
